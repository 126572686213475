
import { computed, defineComponent, ref, onMounted } from 'vue'
import { useStore } from "vuex";
import { useRoute, useRouter } from 'vue-router';
import {  Actions } from "@/store/enums/StoreEnums";
import { useUpdateCustomerData } from '@/store/composable/Customer'

export default defineComponent({
    setup() {

        const apps = ref([
            {
                label: 'Health SKYE',
                value: 'health'
            },
            {
                label: 'Misk SKYE',
                value: 'misk'
            },
        ])

        
        const value = ref<string>('Car SKYE');
        const router = useRouter()
        const store = useStore();


        const switchApp = computed(() => {
            let currentUser =  store.getters.currentUser;
            
            /**
             * 1  : Admin
             * 9  : Sales Manager
             * 6  : Accountant
             * 4  : Underwriter
             * 11 : Marketing Manger
             */
            return (currentUser.can_switch_app !== undefined) ? currentUser.can_switch_app : false;
        })



        const redirect = async(switchTo) => {

            const refresh_token = window.localStorage.getItem('refresh_token') || ''
            const access_token  = window.localStorage.getItem('id_token') || ''
            const user          = JSON.parse(window.localStorage.getItem('user') || '')
            
            if(access_token && refresh_token && user){
                const link = await url(refresh_token,switchTo)
                const payload = {
                        'agent':true,
                        'switch_to':switchTo,
                        'user_id':user.id,
                        'switch_path':link.href,
                };  
                    
                /**
                 * Awaiting to complete the switch app API request
                 * From switch app api, switchapp-{userid} websocket 
                 * is firing. This websocket is responsible for Page redirection
                 */
                const data = await store.dispatch(Actions.SWITCH_APP_RELOAD, payload);
                console.log('data',data)
                window.location.href = data.data.data.switch_app_url
                
            }else{
                window.location.reload;
            }
        }

        const url = async(refreshToken,switchTo) => {
            return router.resolve({ name: 'switch-app', params: { refreshToken,switchTo  } })
        }

        onMounted(() => {
            getSwitchApp()
        })

        async function getSwitchApp() {
            const params = {
                linkType: 'get-switch-app-dropdown',
            }
            const response = await useUpdateCustomerData(params)
            // console.log('switchapp',response.data.data)
            if(response.data.data){
                let data = response.data.data

                if(data.length > 0){
                    data = data.filter( x => x.value != 'car');
                }

                apps.value = data
            }
        }
                

        return {
            switchApp,
            apps,
            value,
            redirect,
        }
    },
})
